import React, {useState} from "react";
import {HiOutlineCalendar, HiOutlineTrendingUp} from "react-icons/hi";
import {useSelector} from "react-redux";
import LineChart from "../components/charts/LineChart";
import BarChart from "../components/charts/BarChart";

const Analytics = (props) => {
    const [dateRange, setDateRange] = useState("Last 30 Days");
    const campaigns = useSelector((state) => state.organisation?.currentOrganisation?.campaigns);
    const members = useSelector((state) => state.organisation?.currentOrganisation?.members);
    const combinedPointsByMonth = calculateCumulativePoints()

    const totalMembers = members ? members.length : 0;
    const activeCampaigns = campaigns ? campaigns.filter(campaign => campaign.status === "running").length : 0;
    const pointsRedeemed = members ? members.reduce((total, member) => {
        // Sum points for the current member
        const memberPoints = member.redeemedPoints.reduce((sum, obj) => sum + obj.points, 0);
        return total + memberPoints;
    }, 0) : 0;

    const totalTransactions = members
        ? members.reduce((sum, member) => sum + (member.transactions ? member.transactions.length : 0), 0)
        : 0;

    const metrics = [
        {label: "Total Members", value: totalMembers, icon: <HiOutlineTrendingUp className="text-primary w-6 h-6"/>},
        {
            label: "Active Campaigns",
            value: activeCampaigns,
            icon: <HiOutlineTrendingUp className="text-green-500 w-6 h-6"/>
        },
        {
            label: "Points Redeemed",
            value: pointsRedeemed,
            icon: <HiOutlineTrendingUp className="text-yellow-500 w-6 h-6"/>
        },
        {
            label: "Transactions",
            value: totalTransactions,
            icon: <HiOutlineTrendingUp className="text-red-500 w-6 h-6"/>
        },
    ];

    function calculateCumulativePoints() {
        // Get the current year
        const currentYear = new Date().getFullYear();

        // Helper to map month indices to names
        const monthNames = [
            "January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"
        ];

        // Initialize an array to store cumulative points for each month
        const cumulativePoints = Array(12).fill(0);

        // Extract all submissions from all campaigns
        const allSubmissions = campaigns ? campaigns.flatMap(campaign => campaign.submissions) : [];

        // Filter submissions to include only those from the current year
        const filteredSubmissions = allSubmissions.filter(submission => {
            const submissionYear = new Date(submission.timestamp).getFullYear();
            return submissionYear === currentYear;
        });

        // Accumulate points month by month
        filteredSubmissions.forEach(submission => {
            const date = new Date(submission.timestamp);
            const monthIndex = date.getMonth(); // Get the zero-based month index

            // Add the points to the corresponding month
            cumulativePoints[monthIndex] += Number(submission.value);
        });

        // Compute cumulative totals
        for (let i = 1; i < cumulativePoints.length; i++) {
            cumulativePoints[i] += cumulativePoints[i - 1];
        }

        // Prepare data for the line chart
        const chartData = monthNames.map((month, index) => ({
            month: month,
            cumulativePoints: cumulativePoints[index]
        }));

        console.log(`chartData ${JSON.stringify(chartData)}`)
        console.log(`campaigns ${JSON.stringify(campaigns)}`)
        return chartData;
    }

    function getPointsForMonth(month) {
        if (combinedPointsByMonth != null) {
            return combinedPointsByMonth.find(m => m.month === month)?.cumulativePoints;
        }
    }

    function calculateTransactionsForBarChart() {
        // Get the current year
        const currentYear = new Date().getFullYear();

        // Initialize the result structure
        const result = {
            labels: [], // Campaign names for the x-axis
            values: []  // Cumulative transactions for the y-axis
        };

        // Process each campaign
        if (campaigns && campaigns.length > 0) {
            campaigns.forEach(campaign => {
                // Filter submissions to include only those from the current year
                const filteredSubmissions = campaign.submissions.filter(submission => {
                    const submissionYear = new Date(submission.timestamp).getFullYear();
                    return submissionYear === currentYear;
                });

                // Count the number of transactions for this campaign
                const transactionCount = filteredSubmissions.length;

                // Add campaign name and transaction count to the result
                result.labels.push(campaign.name); // Assuming each campaign has a 'name' property
                result.values.push(transactionCount);
            });
        }

        return result;
    }

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            {/* Header */}
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold text-gray-800">Analytics Dashboard</h1>
                <div className="relative">
                    <button className="flex items-center px-4 py-2 bg-white border rounded-lg shadow hover:bg-gray-50">
                        <HiOutlineCalendar className="w-5 h-5 text-gray-500 mr-2"/>
                        {dateRange}
                    </button>
                </div>
            </div>

            {/* Metrics Overview */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                {metrics.map((metric, index) => (
                    <div key={index} className="p-4 bg-white shadow rounded-lg flex items-center">
                        <div className="p-3 bg-primary-50 rounded-full">
                            {metric.icon}
                        </div>
                        <div className="ml-4">
                            <h3 className="text-lg font-semibold text-gray-700">{metric.label}</h3>
                            <p className="text-2xl font-bold text-gray-900">{metric.value}</p>
                        </div>
                    </div>
                ))}
            </div>
            {/* Charts */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
                <div className="p-6 bg-white shadow rounded-lg">
                    <h2 className="text-lg font-semibold text-gray-700 mb-4">Points Trend</h2>
                    <div className="h-64 bg-gray-50 flex items-center justify-center">
                        <LineChart data={{
                            "labels": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                            "values": [getPointsForMonth("January"), getPointsForMonth("February"), getPointsForMonth("March"), getPointsForMonth("April"), getPointsForMonth("May"), getPointsForMonth("June"), getPointsForMonth("July"), getPointsForMonth("August"), getPointsForMonth("September"), getPointsForMonth("October"), getPointsForMonth("November"), getPointsForMonth("December")]
                        }} />
                    </div>
                </div>
                <div className="p-6 bg-white shadow rounded-lg">
                    <h2 className="text-lg font-semibold text-gray-700 mb-4">Campaign Performance</h2>
                    <div className="h-64 bg-gray-50 flex items-center justify-center">
                        <BarChart data={calculateTransactionsForBarChart()}/>
                    </div>
                </div>
            </div>

            {/* Detailed Data */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="p-6 bg-white shadow rounded-lg">
                    <h2 className="text-lg font-semibold text-gray-700 mb-4">Top Campaigns</h2>
                    <ul className="space-y-4">
                        {campaigns
                            ?.slice() // Create a shallow copy to avoid mutating the original array
                            .map((campaign) => {
                                // Calculate points for each campaign
                                const campaignPoints = campaign.submissions?.reduce(
                                    (sum, submission) => sum + (submission.value || 0),
                                    0
                                );
                                return {...campaign, campaignPoints};
                            })
                            .sort((a, b) => b.campaignPoints - a.campaignPoints) // Sort by campaign points (descending)
                            .map((campaign, index) => (
                                <li
                                    key={index}
                                    className="flex justify-between items-center p-4 bg-gray-50 rounded-lg shadow"
                                >
                                    <div>
                                        <h3 className="font-bold text-gray-800">{campaign.name}</h3>
                                        <p className="text-gray-600">Points: {campaign.campaignPoints}</p>
                                    </div>
                                    <span
                                        className={`px-3 py-1 text-sm font-semibold rounded ${
                                            campaign.status === "running"
                                                ? "bg-green-100 text-green-700"
                                                : "bg-red-100 text-red-700"
                                        }`}
                                    >
                                         {campaign.status}
                                     </span>
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="p-6 bg-white shadow rounded-lg">
                    <h2 className="text-sm font-semibold text-gray-700 mb-4">Top Members</h2>
                    <ul className="space-y-4">
                        {members
                            ?.slice() // Create a shallow copy to avoid mutating the original array
                            .sort((a, b) => (b.points || 0) - (a.points || 0)) // Sort by points (descending)
                            .map((member, index) => {
                                const totalRedeemedPoints = member.redeemedPoints?.reduce(
                                    (sum, entry) => sum + (entry.points || 0),
                                    0
                                ) || 0; // Calculate total redeemed points

                                return (
                                    <li
                                        key={index}
                                        className="flex justify-between items-center p-4 bg-gray-50 rounded-lg shadow"
                                    >
                                        <div className="flex flex-col w-full">
                                            <h3 className="font-medium text-gray-800 text-sm mb-1">{member.name}</h3>
                                            <div
                                                className="flex items-center text-gray-600 mt-1 divide-x divide-gray-300 text-xs">
                                                <p className="w-1/4 px-2 text-center">
                                                    Transactions: {member.transactions?.length || 0}
                                                </p>
                                                <p className="w-1/4 px-2 text-center">
                                                    Points: {member.points || 0}
                                                </p>
                                                <p className="w-1/4 px-2 text-center">
                                                    Redeemed Points: {totalRedeemedPoints}
                                                </p>
                                                <p className="w-1/4 px-2 text-center truncate"
                                                   title={member.identification}>
                                                    ID: {member.identification}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </div>

            </div>
        </div>
    );
};

export default Analytics;
