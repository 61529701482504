import { useMemo, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setInitialAuthState } from "./redux/actions/authActions";
import Navbar from "./components/shared/Navbar";
import Leftbar from "./components/shared/Leftbar";

const PrivateRoute = ({ userData }) => {
    const isAuthenticated = useMemo(() => {
        return (userData, accessToken) => {
            return userData && accessToken;
        };
    }, []);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem("profile");
    const accessToken = JSON.parse(token)?.accessToken;

    useEffect(() => {
        if (!isAuthenticated(userData, accessToken)) {
            dispatch(setInitialAuthState(navigate));
        }
    }, [dispatch, navigate, userData, accessToken, isAuthenticated]);

    const [showLeftbar, setShowLeftbar] = useState(true); // Ensure Leftbar is shown by default

    const toggleLeftbar = () => {
        setShowLeftbar(!showLeftbar);
    };

    return isAuthenticated(userData, accessToken) ? (
        <div className="scroll-smooth">
            <Navbar
                userData={userData}
                toggleLeftbar={toggleLeftbar}
                showLeftbar={showLeftbar}
            />

            <div className="md:mx-auto md:w-10/12 grid grid-cols-1 md:grid-cols-[auto,1fr] gap-6">
                {/* Leftbar */}
                <div
                    className={`fixed md:relative top-0 left-0 z-50 h-screen md:h-auto w-16 md:w-auto bg-white md:bg-transparent shadow-lg md:shadow-none ${
                        showLeftbar ? "block" : "hidden"
                    } md:block`}
                >
                    <Leftbar />
                </div>

                {/* Main Content */}
                <div
                    className={`w-full ${
                        showLeftbar ? "ml-16 md:ml-0" : "ml-0"
                    } mt-16 md:mt-0`}
                >
                    <Outlet />
                </div>
            </div>
        </div>
    ) : (
        <Navigate to="/signin" />
    );
};

export default PrivateRoute;
