import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {MembersList} from "../components/members/MembersList";
import SingleMemberDetails from "../components/members/SingleMemberDetails";

const Members = () => {
    const members = useSelector((state) => state.organisation?.currentOrganisation?.members);
    const campaigns = useSelector((state) => state.organisation?.currentOrganisation?.campaigns);

    const [currentMember, setCurrentMember] = useState(null);

    const onMemberClick = (member) => {
        setCurrentMember(member);
    }

    const onMemberClose = () => {
        setCurrentMember(null);
    }

    return (
        <div className="main-section">
            {currentMember ? <SingleMemberDetails campaigns={campaigns} member={currentMember} onMemberClose={onMemberClose}/> : <MembersList onMemberClick={onMemberClick} members={members}/>}
        </div>
    );
};

export default Members;
