import {useState} from "react";

export const SubmissionMenu = (props) => {
    const [name, setName] = useState("");
    const [identification, setIdentification] = useState("");
    const [value, setValue] = useState(0);

    return <div className="mt-12 me-8 self-end shadow z-10 fixed rounded-s border bg-white justify-between flex flex-col w-fit h-fit p-1">
        <input
            className={`mb-2 w-40 ps-2 text-xs border rounded h-8`}
            type="text"
            placeholder="Enter Identification"
            value={identification}
            onChange={(event) => setIdentification(event.target.value)}/>
        <input
            className={`mb-2 w-40 ps-2 text-xs border rounded h-8`}
            type="text"
            placeholder="Enter Name (optional)"
            value={name}
            onChange={(event) => setName(event.target.value)}/>

        <input
            className={`mb-2 w-40 ps-2 text-xs border rounded h-8`}
            type="number"
            placeholder="0"
            value={value < 0 ? 0 : value}
            onChange={(event) => setValue(event.target.value)}/>
        <button onClick={() => {
            if (value > 0) {
                props.send(identification, name, value);
            }
        }} className="text-sm text-gray-900 border rounded hover:text-white hover:bg-primary">Send</button>
    </div>
}