import {Fragment, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {AddButton} from "../shared/AddButton";
import {
    Dropdown,
    DROPDOWN_TYPE_CONDITION_BASE,
    DROPDOWN_TYPE_METRIC,
    DROPDOWN_TYPE_CONDITION_OPERATOR,
    DROPDOWN_TYPE_REWARD_OPERATOR,
    DROPDOWN_TYPE_REWARD_BASE,
    conditionBaseValues,
    operatorValues,
    mathematicalOperationValues, rewardBaseValues, metricValues
} from "../shared/Dropdow";
import {Snackbar} from "../shared/Snackbar";
import {HiX} from "react-icons/hi";
import {useDispatch} from "react-redux";
import {createCampaignAction} from "../../redux/actions/campaignActions";
import {generateUniqueId} from "../../utils/idUtils";

//TODO export constants
const TRANSACTION = "transaction"
const BEHAVIOUR = "behaviour"

export const FRAUD_PREVENTION_MAX_POINTS = "fraud_prevention_max_points"
export const FRAUD_PREVENTION_MAX_TRANSACTIONS = "fraud_prevention_max_transactions"

export default function CampaignBuilderModal(props) {
    const dispatch = useDispatch();
    const cancelButtonRef = useRef(null)
    const [missingValue, setMissingValue] = useState(null)
    const [snackbarMessage, setSnackbarMessage] = useState(null)
    // const [fraudPreventionEnabled, setFraudPreventionEnabled] = useState(null)
    const newConditionData = () => {return {id: generateUniqueId(), type: TRANSACTION/*TODO add type selection in the condition builder*/, inputValue: "", transactionBase: conditionBaseValues[0], transactionOperator: operatorValues[0], valueMetric: metricValues[0]}}
    const newRewardsData = () => {return {id: generateUniqueId(), rewardBase: rewardBaseValues[0], operator: mathematicalOperationValues[0], points: null}}
    const newCampaignInitialData = () => {return {name: "", conditions: [newConditionData()], rewards: [newRewardsData()], fraudPrevention: {}}}

    const [newCampaign, setNewCampaign] = useState(newCampaignInitialData())

    const renderRuleBuilder = () => {
        return (
            <div className='flex flex-col w-full'>
                <span className="font-bold mb-2 text-xs self-start">New Rule</span>
                <span className="ms-3 mb-3 text-xs self-start">Conditions</span>
                {newCampaign.conditions.map((condition) => renderConditionBuilder(condition.id))}
                <AddButton className="mt-5" text={"Add Condition"} onClick={() => {
                    try {
                        let mValue = null;
                        newCampaign.conditions.forEach(condition => {
                            if (condition.inputValue.length === 0) {
                                mValue = condition.id;
                            }
                        })

                        if (mValue) {
                            setMissingValue(mValue);
                            setInterval(() => setMissingValue(null), 5000);
                        } else {
                            newCampaign.conditions.push(newConditionData());

                            recreateBuilder()
                        }
                    } catch (e) {
                    }
                }}/>
                <hr className="self-center my-2"/>
                <span className="ms-3 mb-3 text-xs self-start">Rewards</span>
                {newCampaign.rewards.map((rewards) => renderRewardsBuilder(rewards.id))}
                <AddButton className="mt-5" text="Add Rewards" onClick={() => {
                    try {
                        let mValue = null;
                        newCampaign.rewards.forEach(reward => {
                            if (!reward.points || reward.points.length === 0) {
                                mValue = reward.id;
                            }
                        })

                        if (mValue) {
                            setMissingValue(mValue);
                            setInterval(() => setMissingValue(null), 5000);
                        } else {
                            newCampaign.rewards.push(newRewardsData());

                            recreateBuilder()
                        }
                    } catch (e) {
                    }
                }}/>
            </div>
        )
    }

    const renderConditionBuilder = (conditionId) => {
        const componentWidth = "w-30"
        return <div className="mb-2 h-8 flex flex-row w-full justify-between">
            <Dropdown id={conditionId} className={componentWidth} setSelected={setSelected} dropdownType={DROPDOWN_TYPE_CONDITION_BASE}/>
            <Dropdown id={conditionId} className={componentWidth} setSelected={setSelected} dropdownType={DROPDOWN_TYPE_CONDITION_OPERATOR}/>
            <input
                id="conditionBuilderInput"
                className={`${missingValue === conditionId ? "border border-red-600 " : ""} w-20 ps-2 text-xs border rounded h-8 ${componentWidth}`}
                type="number"
                placeholder=""
                value={newCampaign.conditions.find(condition => condition.id === conditionId)?.inputValue}
                onChange={(event) => {
                    //TODO handle behaviour type
                    const realValue = event.target.value < 0 ? 0 : event.target.value;
                    const entry = newCampaign.conditions.find(condition => condition.id === conditionId)
                    if (entry) {
                        entry.inputValue = realValue
                    }

                    recreateBuilder()
                }}/>
            <Dropdown id={conditionId} className={componentWidth} setSelected={setSelected} dropdownType={DROPDOWN_TYPE_METRIC}/>
            <svg onClick={()=> {
                newCampaign.conditions = newCampaign.conditions.filter(condition => condition.id !== conditionId);
                recreateBuilder()
            }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer shadow-2xl ms-3 self-center min-w-min w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
            </svg>
        </div>
    }

    const renderRewardsBuilder = (rewardId) => {
        return <div className="mb-2 h-8 flex flex-row w-full justify-between">
            <div className="flex flex-row justify-between">
                <span className="h-8 p-2 text-xs self-center me-5">Add points with formula</span>
                <Dropdown className="border-r rounded-r-none" id={rewardId} setSelected={setSelected} dropdownType={DROPDOWN_TYPE_REWARD_BASE}/>
                <Dropdown className="border-x-0 rounded-r-none rounded-l-none" id={rewardId} setSelected={setSelected} dropdownType={DROPDOWN_TYPE_REWARD_OPERATOR}/>
                <input
                    className={`${missingValue === rewardId ? "border border-red-600 " : ""} border-l rounded-l-none w-20 ps-2 text-xs border rounded h-8`}
                    type="number"
                    placeholder=""
                    value={newCampaign.rewards.find(reward => reward.id === rewardId)?.points}
                    onChange={(event) => {
                        //TODO handle behaviour type
                        const realPoints = event.target.value < 0 ? 0 : event.target.value;
                        const entry = newCampaign.rewards.find(reward => reward.id === rewardId)
                        if (entry) {
                            entry.points = realPoints
                        }

                        recreateBuilder()
                    }}/>
            </div>
            <svg onClick={()=> {
                newCampaign.rewards = newCampaign.rewards.filter(reward => reward.id !== rewardId);
                recreateBuilder()
            }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer shadow-2xl ms-3 self-center min-w-min w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
            </svg>
        </div>
    }

    const renderFraudPreventionBlock = () => {
        return <div className='flex flex-col'>
            <span className="ms-3 mb-3 text-xs self-start">Fraud prevention</span>
            <div className="flex flex-row w-full justify-between">
                <div className="flex flex-row justify-start">
                    <span className="h-8 p-2 text-xs self-center me-5">Max. points from campaign</span>
                    <input
                        className={`${missingValue === FRAUD_PREVENTION_MAX_POINTS ? "border border-red-600 " : ""} border-l  w-20 ps-2 text-xs border rounded h-8`}
                        type="number"
                        placeholder=""
                        value={newCampaign.fraudPrevention.maxPoints}
                        onChange={(event) => {
                            newCampaign.fraudPrevention.maxPoints = event.target.value
                            recreateBuilder()
                        }}/>
                </div>
                <div className="flex flex-row">
                    <span className="h-8 p-2 text-xs self-center me-5">Max. transactions</span>
                    <input
                        className={`${missingValue === FRAUD_PREVENTION_MAX_TRANSACTIONS ? "border border-red-600 " : ""} border-l w-20 ps-2 text-xs border rounded h-8`}
                        type="number"
                        placeholder=""
                        value={newCampaign.fraudPrevention.maxTransactions}
                        onChange={(event) => {
                            newCampaign.fraudPrevention.maxTransactions = event.target.value
                            recreateBuilder()
                        }}/>
                </div>
            </div>
        </div>
    }

    //utils
    const recreateBuilder = () => { //to trigger reload
        let newBuilder = Object.assign({}, newCampaign);
        setNewCampaign(newBuilder);
    }

    const setSelected = (conditionRewardId, type, dropdownValue) => {
        if (type === DROPDOWN_TYPE_CONDITION_BASE) {
            newCampaign.conditions.find(condition => condition.id === conditionRewardId).transactionBase = dropdownValue;
        }else if (type === DROPDOWN_TYPE_CONDITION_OPERATOR) {
            newCampaign.conditions.find(condition => condition.id === conditionRewardId).transactionOperator = dropdownValue;
        } else if (type === DROPDOWN_TYPE_METRIC) {
            newCampaign.conditions.find(condition => condition.id === conditionRewardId).valueMetric = dropdownValue;
        } else if (type === DROPDOWN_TYPE_REWARD_BASE) {
            newCampaign.rewards.find(reward => reward.id === conditionRewardId).rewardBase = dropdownValue;
        } else if (type === DROPDOWN_TYPE_REWARD_OPERATOR) {
            newCampaign.rewards.find(reward => reward.id === conditionRewardId).operator = dropdownValue;
        }
    }

    return (
        <Transition.Root show={true} as={Fragment}>
            <Dialog as="div" className="overflow-auto bg-transparent relative z-10" initialFocus={cancelButtonRef}
                    onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 h-screen w-screen overflow-auto">
                    {snackbarMessage ? <Snackbar text={snackbarMessage}/> : null }
                    <div className="flex items-end justify-end p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="w-96 lg:w-2/5 h-4/6 min-h-1/5 p-5 self-start fixed top-10 lg:right-20 transform overflow-y-auto rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
                                <HiX onClick={props.closeModal} className="absolute top-3 right-4 cursor-pointer" />
                                <input
                                    id="conditionBuilderInput"
                                    className={`${missingValue === "name" ? "border border-red-600 " : ""} self-center ms-5 me-5 mb-5 w-5/6 ps-2 text-xs border rounded h-8`}
                                    type="text"
                                    placeholder="Enter name"
                                    value={newCampaign.name}
                                    onChange={(event) => {
                                        //TODO handle behaviour type
                                        newCampaign.name = event.target.value;
                                        recreateBuilder()
                                    }}/>
                                <div className="mt-5 flex flex-col justify-start h-40 w-full">
                                    {renderRuleBuilder()}
                                    <hr className="self-center my-2"/>
                                    {renderFraudPreventionBlock()}
                                    <hr className="mt-5"/>
                                    <button onClick={async () => {
                                        try {
                                            if (!newCampaign.conditions.find(condition => condition.inputValue.length === 0)
                                                && !newCampaign.rewards.find(reward => !reward.points)) {
                                                if (newCampaign.name.length === 0) {
                                                    setMissingValue("name")
                                                }else if(!newCampaign.fraudPrevention.maxPoints || (!Number.isInteger(Number(newCampaign.fraudPrevention.maxPoints)) || Number(newCampaign.fraudPrevention.maxPoints) < 1)) {
                                                    setMissingValue(FRAUD_PREVENTION_MAX_POINTS)
                                                }else if(!newCampaign.fraudPrevention.maxTransactions || (!Number.isInteger(Number(newCampaign.fraudPrevention.maxTransactions)) || Number(newCampaign.fraudPrevention.maxTransactions) < 1)) {
                                                    setMissingValue(FRAUD_PREVENTION_MAX_TRANSACTIONS)
                                                }else {
                                                    await dispatch(createCampaignAction(newCampaign));
                                                    props.closeModal();
                                                }
                                            }else {
                                                setSnackbarMessage("Fill out all fields");
                                                setInterval(() => {
                                                    setSnackbarMessage(null)
                                                }, 2000);
                                            }
                                        }catch (e) {

                                        }
                                    }} className="mb-10 mt-10 self-center shadow text-white flex w-100 h-50 bg-primary pt-1 pb-1 pl-2 pr-2 rounded cursor-pointer">Create Campaign</button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
