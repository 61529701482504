import React, { useState, useEffect, useRef } from "react";
import { HiInformationCircle } from "react-icons/hi2";

const SingleMemberDetails = ({ member, campaigns, onCampaignClick, onMemberClose }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [hoveredCampaign, setHoveredCampaign] = useState(null);
    const [activeTab, setActiveTab] = useState("transactions");
    const [selectedProduct, setSelectedProduct] = useState(null); // Tracks selected product for popup view
    const popupRef = useRef(null); // Reference for the product popup

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const getCampaignDetails = (campaignId) => {
        return campaigns.find((campaign) => campaign._id === campaignId);
    };

    const filteredTransactions = member.transactions?.filter((transaction) =>
        `${transaction.campaignId} ${getCampaignDetails(transaction.campaignId)?.name || ""}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
    );

    const closeProductView = () => setSelectedProduct(null); // Close popup

    // Close the product popup when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                closeProductView();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const getPopupPosition = (index) => {
        return "top-1/2 left-full sm:left-auto sm:right-full transform -translate-y-1/2 sm:-translate-x-1/2 sm:translate-x-0";
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            {/* Product Popup */}
            {selectedProduct && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div ref={popupRef} className="bg-white rounded-lg shadow-lg p-6 w-96">
                        <button
                            onClick={closeProductView}
                            className="mb-4 text-primary font-semibold hover:underline flex items-center space-x-2"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 19.5L8.25 12l7.5-7.5"
                                />
                            </svg>
                            <span>Back</span>
                        </button>
                        <h2 className="text-xl font-bold text-gray-800 mb-4">{selectedProduct.name}</h2>
                        <img
                            src={selectedProduct.imageUrl}
                            alt={selectedProduct.name}
                            className="w-full h-48 object-cover rounded-lg mb-4"
                        />
                        <p className="text-gray-600 mb-4">{selectedProduct.description}</p>
                        <p className="text-gray-800 font-semibold">Quantity: {selectedProduct.quantity}</p>
                        <p className="text-primary font-semibold">Price: ${selectedProduct.price}</p>
                    </div>
                </div>
            )}

            <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl mx-auto relative">
                {/* Back Button */}
                <button
                    onClick={onMemberClose}
                    className="mb-4 text-primary font-semibold hover:underline flex items-center space-x-2"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                    </svg>
                    <span>Back</span>
                </button>

                <h1 className="text-2xl font-bold text-gray-800 mb-4">
                    {member.name || "No Name"}
                </h1>
                <p className="text-gray-600 mb-2">
                    <span className="font-semibold">Identification:</span> {member.identification}
                </p>
                <p className="text-gray-600 mb-2">
                    <span className="font-semibold">Points:</span> {member.points || 0}
                </p>

                {/* Tabs */}
                <div className="flex space-x-4 mt-6 border-b pb-2">
                    <button
                        className={`px-4 py-2 font-semibold ${activeTab === "transactions" ? "border-b-2 border-primary text-primary" : "text-gray-600"}`}
                        onClick={() => setActiveTab("transactions")}
                    >
                        Transactions
                    </button>
                    <button
                        className={`px-4 py-2 font-semibold ${activeTab === "redeemedPoints" ? "border-b-2 border-primary text-primary" : "text-gray-600"}`}
                        onClick={() => setActiveTab("redeemedPoints")}
                    >
                        Redeemed Points
                    </button>
                </div>

                {/* Transactions Tab */}
                {activeTab === "transactions" && (
                    <div className="mt-6">
                        <h2 className="text-xl font-semibold text-gray-800 mb-4">
                            Transactions ({filteredTransactions?.length || 0})
                        </h2>
                        <div className="flex justify-between items-center mb-4">
                            <input
                                type="text"
                                placeholder="Search by campaign name or id..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className="block w-64 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                            />
                        </div>
                        <ul className="space-y-4 pb-20">
                            {filteredTransactions?.map((transaction, index) => {
                                const campaignDetails = getCampaignDetails(transaction.campaignId);
                                const hoverKey = `${transaction.campaignId}-${index}`;
                                return (
                                    <li
                                        key={index}
                                        className="p-4 bg-gray-50 rounded-lg shadow flex flex-col sm:flex-row sm:items-center sm:justify-between hover:bg-gray-100 transition relative"
                                    >
                                        <div className="mb-2 sm:mb-0">
                                            <p className="text-sm text-gray-800">
                                                <span className="font-semibold">Member ID:</span> {transaction.memberId}
                                            </p>
                                            <p className="text-sm text-gray-800">
                                                <span className="font-semibold">Value:</span> {transaction.value}
                                            </p>
                                        </div>
                                        <div className="relative flex items-center">
                                            <span className="text-gray-800 font-semibold mr-2">
                                                {campaignDetails?.name || "Unknown Campaign"}
                                            </span>
                                            <div
                                                className="text-gray-500 hover:text-primary focus:outline-none cursor-pointer"
                                                onMouseEnter={() => setHoveredCampaign(hoverKey)}
                                                onMouseLeave={() => setHoveredCampaign(null)}
                                            >
                                                <HiInformationCircle />
                                            </div>
                                            {hoveredCampaign === hoverKey && campaignDetails && (
                                                <div
                                                    className={`absolute ${getPopupPosition(
                                                        index
                                                    )} bg-white shadow-lg rounded-lg p-4 border w-96 z-10`}
                                                >
                                                    <h3 className="text-sm font-bold mb-2">Campaign Details</h3>
                                                    <p className="text-sm text-gray-800">
                                                        <span className="font-semibold">Name:</span> {campaignDetails.name}
                                                    </p>
                                                    <p className="text-sm text-gray-800">
                                                        <span className="font-semibold">Campaign ID:</span> {campaignDetails._id}
                                                    </p>
                                                    <p className="text-sm text-gray-800">
                                                        <span className="font-semibold">Status:</span>{" "}
                                                        {campaignDetails.status === "running" ? "Running" : "Paused"}
                                                    </p>
                                                    <p className="text-sm text-gray-800 font-semibold mt-2">Conditions:</p>
                                                    <ul className="text-sm text-gray-800 list-disc pl-5">
                                                        {campaignDetails.conditions?.map((condition, i) => (
                                                            <li key={i}>
                                                                {condition.type === "transaction"
                                                                    ? `${condition.transactionBase} ${condition.transactionOperator} ${condition.inputValue} ${condition.valueMetric}`
                                                                    : JSON.stringify(condition)}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <p className="text-sm text-gray-800 font-semibold mt-2">Rewards:</p>
                                                    <ul className="text-sm text-gray-800 list-disc pl-5">
                                                        {campaignDetails.rewards?.map((reward, i) => (
                                                            <li key={i}>
                                                                {`Add ${reward.points} to ${reward.rewardBase}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <p className="text-sm text-gray-800 font-semibold mt-2">Fraud Prevention:</p>
                                                    <p className="text-sm text-gray-800">
                                                        Max Points: {campaignDetails.fraudPrevention?.maxPoints}
                                                    </p>
                                                    <p className="text-sm text-gray-800">
                                                        Max Transactions: {campaignDetails.fraudPrevention?.maxTransactions}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}

                {/* Redeemed Points Tab */}
                {activeTab === "redeemedPoints" && (
                    <div className="mt-6">
                        <h2 className="text-xl font-semibold text-gray-800 mb-4">
                            Redeemed Points ({member.redeemedPoints?.length || 0})
                        </h2>

                        {/* Search Bar for Redeemed Points */}
                        <div className="flex justify-between items-center mb-4">
                            <input
                                type="text"
                                placeholder="Search by Order ID..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className="block w-64 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                            />
                        </div>

                        <ul className="space-y-4 pb-20">
                            {member.redeemedPoints
                                ?.filter((redeemedPoint) =>
                                    redeemedPoint.orderId?.toLowerCase().includes(searchTerm.toLowerCase())
                                )
                                .map((redeemedPoint, index) => {
                                    const hoverKey = `redeemed-${index}`;
                                    const isActive = hoveredCampaign === hoverKey;
                                    return (
                                        <li
                                            key={index}
                                            className={`p-4 bg-gray-50 rounded-lg shadow relative hover:bg-gray-100 transition ${
                                                isActive ? "z-10" : ""
                                            }`}
                                            onMouseEnter={() => setHoveredCampaign(hoverKey)}
                                            onMouseLeave={() => setHoveredCampaign(null)}
                                            onClick={() => setHoveredCampaign(isActive ? null : hoverKey)} // For mobile click
                                        >
                                            {/* Entry Information */}
                                            <div className="flex justify-between items-center">
                                                <span className="font-semibold text-gray-800">
                                                    Order ID: {redeemedPoint.orderId}
                                                </span>
                                                <span className="text-gray-600">Points: {redeemedPoint.points}</span>
                                            </div>

                                            {/* Products Section */}
                                            <div
                                                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                                                    isActive ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
                                                }`}
                                            >
                                                {isActive && redeemedPoint.products?.length > 0 && (
                                                    <div className="mt-4 overflow-x-auto whitespace-nowrap bg-gray-100 rounded-lg shadow-inner p-4">
                                                        <div className="flex space-x-4">
                                                            {redeemedPoint.products.map((product, productIndex) => (
                                                                <div
                                                                    key={productIndex}
                                                                    className="bg-white p-4 rounded-lg shadow flex-shrink-0 w-60 cursor-pointer"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setSelectedProduct(product);
                                                                    }} // Open popup
                                                                >
                                                                    <img
                                                                        src={product.imageUrl}
                                                                        alt={product.name}
                                                                        className="w-full h-32 object-cover rounded-lg mb-2"
                                                                    />
                                                                    <h3 className="font-semibold text-gray-800">
                                                                        {product.name}
                                                                    </h3>
                                                                    <p className="text-gray-600 text-sm line-clamp-2">
                                                                        {product.description}
                                                                    </p>
                                                                    <p className="text-gray-800 font-semibold mt-2">
                                                                        Quantity: {product.quantity}
                                                                    </p>
                                                                    <p className="text-primary font-semibold mt-1">
                                                                        Price: ${product.price}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SingleMemberDetails;
