import React, { useState, useEffect, useRef } from 'react';
import ApiKeyComponent from "../components/settings/ApiKeyComponent";
import { Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import EditIcon from "../assets/ic_pencil.svg";
import { verifyAndSaveDomain } from "../redux/api/organisationAPI";

const SettingsPage = () => {
    const [domain, setDomain] = useState('');
    const [isDomainValid, setIsDomainValid] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isEditable, setIsEditable] = useState(true);
    const [highlightInput, setHighlightInput] = useState(false);
    const [verificationFailed, setVerificationFailed] = useState(false);
    const inputRef = useRef(null);

    const currentOrganisation = useSelector((state) => state.organisation?.currentOrganisation);

    useEffect(() => {
        if (currentOrganisation?.settings?.shop?.isVerified) {
            const customDomain = currentOrganisation?.settings?.shop?.customShopDomain;
            setDomain(`${customDomain}`.replace("https://", ""));
            setIsVerified(true);
            setIsSaved(true);
            setIsEditable(false);
        } else {
            // Reset states if the organisation changes and the domain is not verified
            setDomain('');
            setIsVerified(false);
            setIsSaved(false);
            setIsEditable(true);
        }
    }, [currentOrganisation]);


    const triggerHighlight = (color) => {
        setHighlightInput(color);
        setTimeout(() => setHighlightInput(false), 1000); // Remove highlight after 1 second
    };

    const handleVerificationAndSave = async () => {
        setIsVerifying(true);

        try {
            const result = await verifyAndSaveDomain(domain, currentOrganisation?._id);
            setIsVerifying(false);
            if (result && result.data) {
                setIsVerified(true);
                setIsSaved(true);
                setIsEditable(false);
                setVerificationFailed(false);

                // Hide information directly after successful verification
                setIsDomainValid(false);
            } else {
                setIsSaved(false);
                setIsEditable(true);
                setVerificationFailed(true);
                triggerHighlight('red');
                setTimeout(() => {
                    setVerificationFailed(false);
                }, 3000);
            }
        } catch (e) {
            setIsVerifying(false);
            setIsVerified(false);
            setIsSaved(false);
            setVerificationFailed(true);
            triggerHighlight('red');
            setTimeout(() => {
                setVerificationFailed(false);
            }, 3000);

            console.error(e);
        }
    };

    const handleDomainChange = (e) => {
        const value = e.target.value;
        const domainIsValid = validateDomain(value);
        setDomain(value);
        setIsDomainValid(domainIsValid);

        if (domainIsValid) {
            setIsEditable(true); // Ensure the input stays editable
            setVerificationFailed(false); // Hide the verification failed message
            if (!isVerified) {
                // If not verified, show the info and verify/save button
                setIsSaved(false);
            }
        } else {
            // If domain becomes invalid, reset states related to success and verification
            setIsVerified(false);
            setIsSaved(false);
        }
    };

    const handleEditClick = () => {
        setIsDomainValid(true); // Ensure the domain is valid for the info to show
        setIsVerified(false); // Reset verified status
        setIsSaved(false); // Hide success layout
        setIsEditable(true); // Make input editable
        triggerHighlight('blue'); // Highlight in blue
        inputRef.current.focus(); // Focus on the input field
    };

    const validateDomain = (domain) => {
        return /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(domain);
    };

    const verificationString = currentOrganisation?.settings?.shop?.metaData?.domainVerificationString;
    const verificationText = `loyalbase-site-verification=${verificationString}`
    const accessKey = currentOrganisation?.accessKeys[0]; // TODO: handle multiple

    return (
        <div className="bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl w-full space-y-8">
                <form className="mt-8 space-y-6 bg-white p-8 rounded-lg shadow-md">
                    <div className="space-y-8">
                        <div>
                            <h3 className="text-xl font-medium text-gray-900">API Key Instructions</h3>
                            <div className="mt-2 text-sm text-gray-600">
                                <p>Your API Key is a unique identifier that allows you to authenticate and securely access our API. Follow these steps to use your API Key:</p>
                                <ol className="list-decimal list-inside space-y-2 mt-4">
                                    <li>Copy the API Key provided below.</li>
                                    <li>Include it in the Authorization header of your API requests as a Bearer token.</li>
                                    <li>For example, when making a request, set the Authorization header like this:
                                        <pre className="bg-gray-100 p-2 mt-2 rounded-md"><code>Authorization: Bearer YOUR_API_KEY</code></pre>
                                    </li>
                                    <li>Keep your API Key secure and do not share it publicly.</li>
                                </ol>
                            </div>
                        </div>

                        <ApiKeyComponent apiKey={accessKey} />

                        <div>
                            <h3 className="text-xl font-medium text-gray-900">Shop Domain Instructions</h3>
                            <div className="mt-4">
                                <label htmlFor="subdomain" className="block text-sm font-medium text-gray-700">
                                    Enter Domain
                                </label>
                                <div className="flex items-center relative group">
                                    <span className="bg-gray-100 text-gray-500 px-3 py-2 border border-r-0 border-gray-300 rounded-l-md shadow-sm text-sm">
                                        https://
                                    </span>
                                    <input
                                        id="subdomain"
                                        name="subdomain"
                                        type="text"
                                        value={domain}
                                        onChange={handleDomainChange}
                                        ref={inputRef}
                                        className={`appearance-none block w-full pl-1 pr-3 py-2 border border-gray-300 rounded-r-md shadow-sm placeholder-gray-500 text-gray-900 focus:outline-none sm:text-sm transition-all duration-300 ${
                                            highlightInput === 'blue' ? 'ring-2 ring-blue-400' :
                                                highlightInput === 'red' || verificationFailed ? 'ring-2 ring-red-400' : 'focus:ring-primary-500 focus:border-primary-500'
                                        }`}
                                        placeholder="e.g., shop.example.com"
                                        readOnly={!isEditable}
                                    />
                                    {isSaved && !isEditable && (
                                        <button
                                            type="button"
                                            onClick={handleEditClick}
                                            className="absolute right-[35px] text-gray-500 group-hover:text-gray-700 focus:outline-none transition-opacity duration-300 opacity-0 group-hover:opacity-100"
                                        >
                                            <img className='h-5 w-5' src={EditIcon} />
                                        </button>
                                    )}
                                    {(isVerified || (isDomainValid && !isSaved && !verificationFailed)) && (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-green-500 absolute right-3"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a 1 1 0 001.414 0l8-8a 1 1 0 000-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    )}
                                </div>
                            </div>

                            {/* Instructions Section */}
                            <Transition
                                show={isDomainValid}
                                enter="transition-opacity duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity duration-500"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="mt-4 text-sm text-gray-600">
                                    <ol className="list-decimal list-inside space-y-2">
                                        <li>Log in to your domain registrar's website.</li>
                                        <li>Navigate to the <strong>DNS settings</strong> or domain management section.</li>
                                        <li>Create a new <strong>A record</strong>:</li>
                                        <ol className="list-decimal list-inside ml-5 space-y-2">
                                            <li>In the <strong>Host</strong> field, enter the subdomain you want to use (e.g., <code><strong>shop</strong>.xyz.com</code>). Leave it blank if you're setting this for the root domain (e.g., <code>xyz.com</code>).</li>
                                            <li>In the <strong>Points to</strong> field, enter <code>18.156.180.220</code>.</li>
                                            <li>Save the record and allow time for the DNS changes to propagate.</li>
                                        </ol>

                                        {/* Hide only Verification section when saved */}
                                        {!isSaved && (
                                            <li><strong>Verification:</strong> To verify domain ownership, follow these steps:
                                                <ul className="list-disc list-inside ml-4 space-y-1">
                                                    <li>Create a new <strong>TXT</strong> record in your DNS settings.</li>
                                                    <li>In the <strong>Host</strong> or <strong>Name</strong> field, enter <code>@</code> for the root domain or the specific subdomain.</li>
                                                    <li>In the <strong>Value</strong> field, enter the following verification string:</li>
                                                    <div className="mt-2 p-4 bg-gray-100 rounded-md">
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            value={verificationText}
                                                            className="w-full p-2 border border-gray-300 rounded-md bg-white text-gray-900"
                                                        />
                                                    </div>
                                                    <li>Save the record and wait for the DNS changes to propagate.</li>
                                                </ul>
                                            </li>
                                        )}
                                    </ol>
                                </div>
                            </Transition>

                            {/* After Verification: Show Verified Domain */}
                            {isSaved && (
                                <div className="mt-4 p-4 bg-green-50 border-l-4 border-green-400">
                                    <h4 className="text-green-700">Domain Verified</h4>
                                    <p>Domain <strong>{domain}</strong> is verified.</p>
                                </div>
                            )}

                            {/* Verification and Save Button */}
                            {isDomainValid && !isSaved && (
                                <button
                                    type="button"
                                    onClick={handleVerificationAndSave}
                                    className={`mt-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                                        isVerifying ? 'bg-gray-400 cursor-wait' : verificationFailed ? 'bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500' : 'bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                                    }`}
                                    disabled={isVerifying}
                                >
                                    {isVerifying ? (
                                        <span>Verifying...</span>
                                    ) : verificationFailed ? (
                                        <span>Verification Failed</span>
                                    ) : (
                                        <span>Verify and Save</span>
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SettingsPage;
