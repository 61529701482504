import {useSelector} from "react-redux";
import {useState} from "react";
import {SingleCampaign} from "./SingleCampaign";
import {CampaignGrid} from "../components/campaigns/CampaignGrid";

const Campaigns = () => {
    const campaigns = useSelector((state) => state.organisation?.currentOrganisation?.campaigns);
    const [currentCampaign, setCurrentCampaign] = useState(null);

    const onCampaignClick = (campaign) => {
        setCurrentCampaign(campaign);
    }

    const onCampaignClose = () => {
        setCurrentCampaign(null);
    }

    return (
        <div className="main-section">
            {currentCampaign ? <SingleCampaign campaign={currentCampaign} onClose={onCampaignClose}/> : <CampaignGrid onCampaignClick={onCampaignClick} campaigns={campaigns}/>}
        </div>
    );
};

export default Campaigns;
