import { ConditionBuilder } from "../components/shared/ConditionBuilder";
import { RewardBuilder } from "../components/shared/RewardBuilder";
import { useState } from "react";
import { DeleteDialog } from "../components/shared/DeleteDialog";
import { HiChevronLeft } from "react-icons/hi2";
import {
    DROPDOWN_TYPE_CONDITION_BASE,
    DROPDOWN_TYPE_CONDITION_OPERATOR, DROPDOWN_TYPE_METRIC,
    DROPDOWN_TYPE_REWARD_BASE, DROPDOWN_TYPE_REWARD_OPERATOR
} from "../components/shared/Dropdow";
import { useDispatch } from "react-redux";
import {
    deleteCampaignAction,
    submitAction,
    updateCampaignAction
} from "../redux/actions/campaignActions";
import { Snackbar } from "../components/shared/Snackbar";
import { CampaignMenu } from "../components/shared/CampaignMenu";
import { SubmissionMenu } from "../components/shared/SubmissionMenu";
import { FraudPreventionBuilder } from "../components/shared/FraudPreventionBuilder";
import {
    FRAUD_PREVENTION_MAX_POINTS,
    FRAUD_PREVENTION_MAX_TRANSACTIONS
} from "../components/modals/CampaignBuilderModal";
import {Transition} from "@headlessui/react";

export const CONDITION_VALUE = "condition-value";
export const REWARD_POINTS = "reward-points";
export const FRAUD_PREVENTION = "fraud-prevention";

export const SingleCampaign = (props) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [newCampaignData, setNewCampaignData] = useState(props.campaign)
    const [missingValue, setMissingValue] = useState(null)
    const [publishing, setPublishing] = useState(false)
    const [showTestSubmissionSucceeded, setShowTestSubmissionSucceeded] = useState(false)
    const [showSubmissionDialog, setShowSubmissionDialog] = useState(false);
    const [message, showMessage] = useState("")
    const dispatch = useDispatch();


    const setSelected = (conditionRewardId, type, dropdownValue) => {
        if (type === DROPDOWN_TYPE_CONDITION_BASE) {
            newCampaignData.conditions.find(condition => condition.id === conditionRewardId).transactionBase = dropdownValue;
        } else if (type === DROPDOWN_TYPE_CONDITION_OPERATOR) {
            newCampaignData.conditions.find(condition => condition.id === conditionRewardId).transactionOperator = dropdownValue;
        } else if (type === DROPDOWN_TYPE_METRIC) {
            newCampaignData.conditions.find(condition => condition.id === conditionRewardId).valueMetric = dropdownValue;
        } else if (type === DROPDOWN_TYPE_REWARD_BASE) {
            newCampaignData.rewards.find(reward => reward.id === conditionRewardId).rewardBase = dropdownValue;
        } else if (type === DROPDOWN_TYPE_REWARD_OPERATOR) {
            newCampaignData.rewards.find(reward => reward.id === conditionRewardId).operator = dropdownValue;
        }
    }

    const handlePublish = async () => {
        setPublishing(true)
        await dispatch(updateCampaignAction(newCampaignData))
        setPublishing(false)
    }
    const handleDeletion = async () => {
        setShowDeleteDialog(false);
        await dispatch(deleteCampaignAction(props.campaign._id))
        props.onClose()
    }
    const showDialog = () => {
        setShowDeleteDialog(true)
    }
    const hideDialog = () => {
        setShowDeleteDialog(false)
    }

    const changeStatus = () => {
        newCampaignData.status = newCampaignData.status === "running" ? "paused" : "running"
        recreateCampaign();
    }

    const onInputChange = (id, type, value) => {
        try {
            const realValue = value < 0 ? 0 : value;
            if (type === CONDITION_VALUE) {
                newCampaignData.conditions.find(condition => condition.id === id).inputValue = realValue;
            } else if (type === REWARD_POINTS) {
                newCampaignData.rewards.find(reward => reward.id === id).points = realValue;
            } else if (type === FRAUD_PREVENTION) {
                if (id === FRAUD_PREVENTION_MAX_POINTS) {
                    newCampaignData.fraudPrevention.maxPoints = realValue;
                } else if (id === FRAUD_PREVENTION_MAX_TRANSACTIONS) {
                    newCampaignData.fraudPrevention.maxTransactions = realValue;
                }
            }

            recreateCampaign();
        } catch (e) {
            console.error(e);
        }
    }

    //not working yet. for showing the publish button only if there is a change
    const recreateCampaign = () => {
        let newBuilder = Object.assign({}, newCampaignData);
        setNewCampaignData(newBuilder);
    }
    const hasDataChanged = () => {
        if (newCampaignData.name !== props.campaign.name) {
            return true
        }

        for (let newCondition of newCampaignData.conditions) {
            const initialCondition = props.campaign.conditions.find(initialCondition => initialCondition.id === newCondition.id);
            if (initialCondition && ((initialCondition.inputValue !== newCondition.inputValue) || (initialCondition.transactionBase !== newCondition.transactionBase) || (initialCondition.transactionOperator !== newCondition.transactionOperator))) {
                return true
            }
        }

        for (let newReward of newCampaignData.rewards) {
            const initialReward = props.campaign.rewards.find(initialReward => initialReward.id === newReward.id);
            if (initialReward && ((initialReward.points !== newReward.points) || (initialReward.rewardBase !== newReward.rewardBase) || (initialReward.operator !== newReward.operator))) {
                return true
            }
        }

        return false;
    }

    return <div className="pb-5 flex flex-col w-10/12 px-4 text-center bg-white border border-gray-200 rounded shadow sm:px-8">
        {showTestSubmissionSucceeded ? <Snackbar onClose={() => {
            setShowTestSubmissionSucceeded(false)
        }} text="Test submission sent!" successIcon hideCloseButton/> : null}
        {showSubmissionDialog ? <SubmissionMenu send={async (identification, name, value) => {
            await dispatch(submitAction({campaignId: props.campaign._id, identification, name, value}))
            setShowSubmissionDialog(false)
            setShowTestSubmissionSucceeded(true);
        }}/> : null}

        {message.length > 0 ? <Snackbar onClose={() => {
            showMessage("")
        }} text={message} hideCloseButton/> : null}

        <div className="w-full mt-4 mb-4 flex flex-row justify-between">
            <HiChevronLeft className="self-center cursor-pointer" onClick={props.onClose}/>
            <CampaignMenu showMessage={showMessage} showSubmissionDialog={showSubmissionDialog} setShowSubmissionDialog={setShowSubmissionDialog} campaign={props.campaign}/>
        </div>
        <Transition
            show={showDeleteDialog}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <DeleteDialog noFunction={hideDialog} yesFunction={handleDeletion} />
        </Transition>
        <h5 className="mb-5 text-3xl font-bold text-gray-900">{newCampaignData.name}</h5>
        <button
            onClick={changeStatus}
            className={`${props.campaign.status === "running" ? "bg-primary text-white" : "text-gray-900 hover:text-white hover:bg-primary "} self-center w-fit ms-4 border px-2 py-1 rounded`}>{newCampaignData.status}</button>
        <h3 className="self-start mt-10 mb-5 text-2xl font-bold text-gray-900">Conditions</h3>
        {newCampaignData.conditions.map(condition => <ConditionBuilder
            includeDeleteButton={newCampaignData.conditions.length > 1} condition={condition} onDelete={handleDeletion}
            onInputChange={onInputChange} missingValue={missingValue} setSelected={setSelected}/>)}
        <h3 className="self-start mt-10 mb-5 text-2xl font-bold text-gray-900">Rewards</h3>
        {newCampaignData.rewards.map(reward => <RewardBuilder  reward={reward} includeDeleteButton={newCampaignData.rewards.length > 1} onDelete={handleDeletion}
                                                               onInputChange={onInputChange} missingValue={missingValue} setSelected={setSelected}
        />)}
        <h3 className="self-start mt-10 mb-5 text-2xl font-bold text-gray-900">Fraud Prevention</h3>
        <FraudPreventionBuilder maxPoints={newCampaignData.fraudPrevention.maxPoints} maxTransactions={newCampaignData.fraudPrevention.maxTransactions} onInputChange={onInputChange} />
        <div className="flex flex-row justify-between">
            <button onClick={showDialog}
                    className={`mt-20 self-start border px-2 py-1 rounded text-gray-900 hover:text-white hover:bg-red-500`}>Delete
            </button>
            <button onClick={handlePublish}
                    className={`${publishing ? "text-gray-900 bg-transparent" : ""} self-end border px-2 py-1 rounded text-white bg-primary`}>
                {publishing ?
                    <svg className="bg-primary rounded animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"/> : "Publish"}
            </button>
        </div>
    </div>
}
