import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChart = ({ data }) => {
    const chartData = {
        labels: data.labels, // Example: ["Campaign A", "Campaign B", "Campaign C"]
        datasets: [
            {
                label: "Campaign Performance",
                data: data.values, // Example: [50, 30, 70]
                backgroundColor: ["#4CAF50", "#FFC107", "#FF5722"],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Campaigns",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Transactions",
                },
                beginAtZero: true,
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default BarChart;
