import React, { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";

export const CampaignGrid = ({ campaigns, onCampaignClick }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredCampaigns = campaigns?.filter((campaign) =>
        campaign.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        campaign.status.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-xl font-bold text-gray-800">Campaigns</h1>
                <div className="relative w-64">
                    <input
                        type="text"
                        placeholder="Search campaigns..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="block w-full px-10 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                    />
                    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                        <HiOutlineSearch className="text-gray-500 w-5 h-5" />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {filteredCampaigns?.length ? (
                    filteredCampaigns.map((campaign, index) => {
                        // Calculate total points and transactions for each campaign
                        const totalPoints = campaign.submissions.reduce(
                            (sum, submission) => sum + (submission.value || 0),
                            0
                        );
                        const totalTransactions = campaign.submissions.length;

                        return (
                            <div
                                key={index}
                                onClick={() => onCampaignClick(campaign)}
                                className="p-4 bg-white shadow-md rounded-lg hover:bg-primary-50 hover:scale-[1.02] transition-transform cursor-pointer"
                            >
                                {/* Campaign Name */}
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-lg font-bold text-gray-800 truncate">
                                        {campaign.name}
                                    </h2>
                                    <span
                                        className={`px-3 py-1 rounded text-xs ${
                                            campaign.status === "running"
                                                ? "bg-green-500 text-white"
                                                : campaign.status === "completed"
                                                    ? "bg-gray-400 text-white"
                                                    : "bg-yellow-500 text-white"
                                        }`}
                                    >
                                        {campaign.status}
                                    </span>
                                </div>

                                {/* Campaign Details */}
                                <div className="text-gray-600 text-sm space-y-2">
                                    {campaign.description && (
                                        <p className="truncate">{campaign.description}</p>
                                    )}
                                    {campaign.startDate && campaign.endDate && (
                                        <p>
                                            <span className="font-semibold">Dates:</span>{" "}
                                            {new Date(campaign.startDate).toLocaleDateString()} -{" "}
                                            {new Date(campaign.endDate).toLocaleDateString()}
                                        </p>
                                    )}
                                    <p>
                                        <span className="font-semibold">Points Awarded:</span> {totalPoints}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Transactions:</span> {totalTransactions}
                                    </p>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="text-center py-6 text-gray-500">
                        No campaigns found. Try adjusting your search.
                    </div>
                )}
            </div>
        </div>
    );
};
