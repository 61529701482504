import React, { useState } from "react";

export const MembersList = ({ members, onMemberClick }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredMembers = members?.filter((member) =>
        member.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.identification?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getAvatarLetter = (member) =>
        member.name ? member.name[0].toUpperCase() : member.identification[0].toUpperCase();

    const getUnrealizedValuePoints = (member) => {
        return member.unrealizedValue.reduce((sum, uv) => sum + (uv.value || 0), 0);
    };

    const getRedeemedPoints = (member) => {
        return member.redeemedPoints.reduce((sum, uv) => sum + (uv.points || 0), 0);
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-xl font-bold text-gray-800">Members</h1>
                <input
                    type="text"
                    placeholder="Search members..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="block w-64 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                />
            </div>
            <div className="overflow-hidden shadow-lg rounded-lg">
                <table className="w-full bg-white text-sm text-left">
                    <thead className="bg-primary text-white">
                    <tr>
                        <th className="px-6 py-3 text-left">Avatar</th>
                        <th className="px-6 py-3 text-left">Name</th>
                        <th className="px-6 py-3 text-left">Identification</th>
                        <th className="px-6 py-3 text-left">Transactions</th>
                        <th className="px-6 py-3 text-left">Points</th>
                        <th className="px-6 py-3 text-left">Redeemed Points</th>
                        <th className="px-6 py-3 text-left">Unrealized Points</th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {filteredMembers?.map((member, index) => (
                        <tr
                            key={index}
                            onClick={() => onMemberClick(member)}
                            className="cursor-pointer hover:bg-primary-100 hover:scale-[1.02] transition-transform"
                        >
                            <td className="px-6 py-4">
                                <div className="flex items-center">
                                    <div className="h-10 w-10 flex items-center justify-center bg-primary-500 text-white rounded-full text-lg font-semibold shadow-md">
                                        {getAvatarLetter(member)}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 font-medium text-gray-900">
                                {member.name || "No Name"}
                            </td>
                            <td className="px-6 py-4">{member.identification}</td>
                            <td className="px-6 py-4">
                                {member.transactions ? member.transactions.length : 0}
                            </td>
                            <td className="px-6 py-4">{member.points || 0}</td>
                            <td className="px-6 py-4">{getRedeemedPoints(member) || 0}</td>
                            <td className="px-6 py-4">{getUnrealizedValuePoints(member) || 0}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {filteredMembers && filteredMembers.length === 0 && (
                    <div className="text-center py-6 text-gray-500">
                        No members found. Try adjusting your search.
                    </div>
                )}
            </div>
        </div>
    );
};
