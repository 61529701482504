import * as api from "../api/campaignAPI";
import * as organisationTypes from "../constants/organisationConstants";

export const createCampaignAction = (ruleBuilder) => async (dispatch, getState) => {
  try {
    const currentOrganisationId = getState().organisation.currentOrganisation._id;
    const { error, data } = await api.createCampaign(ruleBuilder, currentOrganisationId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: organisationTypes.ADD_CAMPAIGN,
      payload: data,
    });

  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};

export const updateCampaignAction = (ruleBuilder) => async (dispatch, getState) => {
  try {
    const currentOrganisationId = getState().organisation.currentOrganisation._id;
    const { error, data } = await api.updateCampaign(ruleBuilder, currentOrganisationId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: organisationTypes.UPDATE_CAMPAIGN,
      payload: data,
    });
  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};

export const deleteCampaignAction = (id) => async (dispatch, getState) => {
  try {
    const currentOrganisationId = getState().organisation.currentOrganisation._id;
    const { error, data } = await api.deleteCampaign(id, currentOrganisationId);

    if (error) {
      throw new Error(error);
    }

    dispatch({
      type: organisationTypes.DELETE_CAMPAIGN,
      payload: data,
    });
  } catch (error) {
    // dispatch({
    //   type: types.GET_USER_FAIL,
    //   payload: error.message,
    // });
  }
};

export const submitAction = (data) => async (dispatch, getState) => {
  const currentOrganisation = getState().organisation.currentOrganisation
  if (currentOrganisation) {
    const currentOrganisationId = currentOrganisation._id;
    const apiKey = currentOrganisation ? currentOrganisation.accessKeys[0] : null; //TODO dynamic API-KEY

    const submission = {
      memberData: {
        identification: data.identification,
        name: data.name,
      },
      submissionData: {
        campaignId: data.campaignId,
        value: data.value
      },
      apiKey: apiKey
    }

    try {
      const { error, data } = await api.submit(submission, currentOrganisationId);

      if (error) {
        throw new Error(error);
      }

      dispatch({
        type: organisationTypes.UPDATE_ORGANISATION,
        payload: data,
      });
    } catch (error) {
      // dispatch({
      //   type: types.GET_USER_FAIL,
      //   payload: error.message,
      // });
    }
  }
};