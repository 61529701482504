import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const LineChart = ({ data }) => {
    const chartData = {
        labels: data.labels,
        datasets: [
            {
                label: "Points Trend",
                data: data.values,
                borderColor: "#4169E1",
                backgroundColor: "rgba(65, 105, 225, 0.2)",
                tension: 0.3,
                pointBackgroundColor: "#4169E1",
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Months",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Points",
                },
                min: 0, // Ensure the y-axis starts at 0
                suggestedMax: Math.max(...data.values) + 10, // Dynamically adjust the max
            },
        },
    };

    return <Line data={chartData} options={options} />;
};

export default LineChart;
